import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { style: {"padding":"10px","margin":"0"} }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { style: {"padding":"0","margin":"0"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_Accordion = _resolveComponent("Accordion")!

  return ($props.destination_members?.length > 2)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Accordion, null, {
          default: _withCtx(() => [
            _createVNode(_component_AccordionTab, {
              header: $props.destination_members?.length + ' получателей'
            }, {
              default: _withCtx(() => [
                _createElementVNode("ul", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.destination_members, (member, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: index,
                      style: {"list-style-type":"circle"}
                    }, _toDisplayString(member?.title ?? member), 1))
                  }), 128))
                ])
              ]),
              _: 1
            }, 8, ["header"])
          ]),
          _: 1
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.destination_members, (member, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              style: {"list-style-type":"circle"}
            }, _toDisplayString(member?.title ?? member), 1))
          }), 128))
        ])
      ]))
}