<template>

    <XModal ref="XModal" :hide-footer="true" :show-close-button="true">

        <iframe ref="iframe" :src="gosedoPortalUrl+'/#/constructor/selector/structuredPartResolver'"
                style="min-width: 1024px; min-height: 65vh; border: 0;"/>

    </XModal>

</template>

<script>

import {XModal} from "@xnpmpackages/xcomponents";
import {Helper} from "@xnpmpackages/xcomponents/dist/index";

export default {
    name: "AddStructuredPartModal",
    data() {
        return {
            promiseResolver: null,
            gosedoPortalUrl: null,
            context: null,
        }
    },
    methods: {

        select(context) {

            this.context = context

            let P = new Promise(resolve => {
                this.promiseResolver = resolve
            });

            window.addEventListener('message', this.handlePostMessage);

            this.$refs.XModal.show();

            return P;

        },

        async handlePostMessage(event) {

            if (this.gosedoPortalUrl && (event.origin !== this.gosedoPortalUrl))
                return;

            if (event?.data?.system_id !== 'GosedoPortal')
                return;

            switch (event?.data?.data?.action) {

                case 'XMLSend':

                    if (!event.data.data?.errors?.length) {
                        await this.$refs.XModal.hide();
                        await this.promiseResolver(event.data.data);
                    }

                    break;

                case 'portalReady':

                    this.$refs.iframe.contentWindow.postMessage({
                        system_id: 'GosedoPortal',
                        data: {
                            action: "addStyleTag",
                            data: {
                                text: ".container{width: 1000px;}"
                            }
                        }
                    }, '*');

                    this.sendContextToPortal();

                    this.portalIsLoading = false;
                    this.portalAvailable = true


                    break;

            }

        },

        sendContextToPortal: function () {
            this.$refs.iframe.contentWindow.postMessage({
                system_id: 'GosedoPortal',
                data: {
                    action: 'initData',
                    data: this.context ? Helper.methods.copyObjectByJSON(this.context) : null
                },
            }, '*');
        },

    },
    computed: {},
    async created() {

        /*let response = await this.$xapi.post(
            '/outbound',
            {
                action: 'getGosedoPortalUrl',
            },
        );

        this.gosedoPortalUrl = response.data.module_gosedo_make_structured_xml_api_url;*/

        this.gosedoPortalUrl = '';

    },
    unmounted() {
        window.removeEventListener('message', this.handlePostMessage);
    },
    components: {
        XModal
    }
}
</script>

<style scoped>

.test:deep(.p-dialog) {
    max-height: 100vh;
}

.test:deep(.p-dialog-header) {
    padding: 0;
    margin: 0;
}

.test:deep(.p-dialog-footer) {
    padding: 0;
    margin: 0;
}

.test:deep(.p-dialog-content) {
    padding: 0;
    margin: 0;
}

</style>